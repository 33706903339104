<template>
  <div>
    <!-- Input INFRAESTRUTURA -->
    <section v-if="option == 'rooms'">
      <v-card class="pa-14">
        <div>
          <p class="float-left ml-n2 mb-8">
            {{ edit ? "Editar Sala" : "Adicionar Sala" }}
            <span v-if="holdAdd" class="ml-5 text-red">
              VERIFIQUE OS DADOS</span
            >
          </p>
          <v-icon @click="handleClose" class="float-right mb-8"
            >mdi-close</v-icon
          >
        </div>

        <v-row class="c-b">
          <v-col cols="4" md="6" class="col-m">
            <!-- BLOCO INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Bloco"
              :rules="roomRules.bloco"
              v-model="editPlaceholder.bloco"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Bloco"
              :rules="roomRules.bloco"
              v-model="room.bloco"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="6" class="col-m">
            <!-- NOME INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Nome"
              :rules="roomRules.nome"
              v-model="editPlaceholder.nome"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Nome"
              :rules="roomRules.nome"
              v-model="room.nome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="4" class="col-m">
            <!-- CAPACIDADE INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              type="number"
              label="Capaciade"
              :rules="roomRules.capacidade"
              v-model="editPlaceholder.capacidade"
            ></v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              type="number"
              label="Capaciade"
              :rules="roomRules.capacidade"
              v-model="room.capacidade"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="8" class="col-m">
            <!-- TIPO INPUT -->
            <v-select
              v-if="edit"
              dense
              :rules="roomRules.tipo"
              v-model="editPlaceholder.tipo"
              :items="roomTypes"
              label="Tipo"
              outlined
            >
            </v-select>
            <v-select
              v-else-if="editPublic"
              dense
              :rules="roomRules.tipo"
              v-model="room.tipo"
              :items="roomTypesCopy"
              label="Tipo"
              outlined
            >
            </v-select>
            <v-select
              v-else
              dense
              :rules="roomRules.tipo"
              v-model="room.tipo"
              :items="roomTypesCopy"
              label="Tipo"
              outlined
            >
              <template v-slot:append-item>
                <v-list-item @click="handleSelect"
                  >Adicionar Novo tipo</v-list-item
                >
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!noPreferences" cols="4" md="6" class="col-m">
            <!-- PREFERENCIAS INPUT -->
            <div
              v-if="edit"
              @click="() => (preferencesDialog = true)"
              class="slider pt-2 pl-3"
            >
              <p class="pb-0 mb-0">
                Preferências
                <HelpIcon
                  text="Defina quais departamentos possuem maior preferência a esta sala."
                  small
                />
              </p>
              <v-icon class="float-right mt-n6 mr-2"
                >mdi-tune-variant</v-icon
              >
            </div>
            <div
              v-else
              @click="() => (preferencesDialog = true)"
              class="slider pt-2 pl-3"
            >
              <p class="pb-0 mb-0">
                Preferências
                <HelpIcon
                  text="Defina quais departamentos possuem maior preferência a esta sala."
                  small
                />
              </p>
              <v-icon class="float-right mt-n6 mr-2"
                >mdi-tune-variant</v-icon
              >
            </div>
          </v-col>
          <v-col v-if="!noPreferences" cols="3" md="6" class="col-m">
            <!-- EXCECAO INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Exceção"
              :rules="roomRules.excecao"
              :hint="`Opcional: horário de indisponibilidade`"
              persistent-hint
              v-model="editPlaceholder.excecao"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Exceção"
              :rules="roomRules.excecao"
              :hint="`Opcional: horário de indisponibilidade`"
              persistent-hint
              v-model="room.excecao"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="col-m">
            <!-- ACESSIVEL CHECKBOX -->
            <v-checkbox
              v-if="edit"
              v-model="editPlaceholder.acessivel"
              :label="`Acessível`"
              class="col-m mt-n1 float-left"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="room.acessivel"
              :label="`Acessível`"
              class="col-m mt-n1 float-left"
            ></v-checkbox>
            <HelpIcon
              class="float-left"
              :text="'Sala acessível há discentes com mobilidade reduzida ou usuários de cadeira de rodas.'"
              small
            />
          </v-col>
          <v-btn
            @click="handleAddRoom"
            class="mx-auto mt-5"
            color="primary"
            >Adicionar</v-btn
          >
        </v-row>
      </v-card>

      <!-- Dialog to edit room type -->
      <v-dialog v-model="roomTypeDialog" persistent max-width="350">
        <SetPreferences
          :preferences="roomTypesCopy"
          onlyAdd
          :save="saveRoomType"
          :close="() => (roomTypeDialog = false)"
        />
      </v-dialog>

      <v-dialog
        v-model="preferencesDialog"
        persistent
        max-width="405"
      >
        <!-- Dialog to edit perferences on edit mode -->
        <div v-if="edit">
          <div v-if="editPlaceholder.preferencias.length == 0">
            <v-icon
              class="float-right"
              @click="() => (preferencesDialog = false)"
              >mdi-close</v-icon
            >
            <p class="float-left">
              Nenhum departamento adicionado. Ao adicionar novas
              tumas, os departamentos aparecerão aqui. Você pode
              editar esta opção depois.
            </p>
          </div>
          <SetPreferences
            v-else
            :preferences="editPlaceholder.preferencias"
            @uptate="editPlaceholder.preferencias = $event"
            :close="() => (preferencesDialog = false)"
          />
        </div>
        <!-- Dialog to edit perferences -->
        <div v-else>
          <div
            v-if="preferences.length == 0"
            class="card-no-preference"
          >
            <v-icon
              class="float-right"
              @click="() => (preferencesDialog = false)"
              >mdi-close</v-icon
            >
            <p class="float-left">
              Nenhum departamento adicionado. Ao adicionar novas
              tumas, os departamentos aparecerão aqui. Você pode
              editar esta opção depois.
            </p>
          </div>
          <SetPreferences
            v-else
            departament
            :save="handleSaveDepartaments"
            :preferences="preferences"
            @uptate="preferences = $event"
            :close="() => (preferencesDialog = false)"
          />
        </div>
      </v-dialog>
    </section>

    <!-- Input CLASSES  -->
    <section v-if="option == 'classes'">
      <v-card class="pa-8">
        <div>
          <p class="float-left ml-n2 mb-8">
            {{ edit ? "Editar Turma" : "Adicionar Turma" }}
            <span v-if="holdAdd" class="ml-5 text-red">
              VERIFIQUE OS DADOS</span
            >
          </p>
          <v-icon @click="handleClose" class="float-right mb-8"
            >mdi-close</v-icon
          >
        </div>

        <v-row class="c-b">
          <v-col cols="4" md="3" class="col-m">
            <!-- CODIGO INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Código"
              :rules="classRules.codigo"
              v-model="editPlaceholder.codigo"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Código"
              :rules="classRules.codigo"
              v-model="classObj.codigo"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="2" class="col-m">
            <!-- TURMA INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Turma"
              :rules="classRules.turma"
              v-model="editPlaceholder.turma"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Turma"
              :rules="classRules.turma"
              v-model="classObj.turma"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" md="3" class="col-m">
            <!-- HORARIO INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Horário"
              :rules="classRules.horario"
              v-model="editPlaceholder.horario"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Horário"
              :rules="classRules.horario"
              v-model="classObj.horario"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="4" class="col-m">
            <!-- DEPARTAMENTO  INPUT -->
            <v-select
              v-if="edit"
              dense
              persistent-hint
              v-model="editPlaceholder.departamento"
              :items="departamentsList"
              item-text="name"
              item-value="short"
              label="Departamento"
              outlined
            >
            </v-select>
            <v-select
              v-else
              dense
              persistent-hint
              v-model="classObj.departamento"
              :items="departamentsList"
              item-text="name"
              item-value="short"
              label="Departamento"
              outlined
            >
            </v-select>
          </v-col>
          <v-col cols="4" md="6" class="col-m">
            <!-- NOME INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Nome"
              :rules="classRules.nome"
              v-model="editPlaceholder.nome"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Nome"
              :rules="classRules.nome"
              v-model="classObj.nome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="6" class="col-m">
            <!-- DOSCENTE INPUT -->
            <v-text-field
              v-if="edit"
              dense
              outlined
              label="Docente"
              :rules="classRules.docente"
              v-model="editPlaceholder.docente"
            >
            </v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              label="Docente"
              :rules="classRules.docente"
              v-model="classObj.docente"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" md="2" class="col-m">
            <!-- ALUNOS INPUT -->
            <v-text-field
              v-if="edit"
              type="number"
              dense
              outlined
              label="Alunos"
              :rules="classRules.alunos"
              v-model="editPlaceholder.alunos"
            >
            </v-text-field>
            <v-text-field
              v-else
              type="number"
              dense
              outlined
              label="Alunos"
              :rules="classRules.alunos"
              v-model="classObj.alunos"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="!noPreferences" cols="4" md="5" class="col-m">
            <!-- PREFERENCIAS INPUT -->
            <div
              v-if="edit"
              @click="() => (classUtils.preferencesDialog = true)"
              class="slider pt-2 pl-3"
            >
              <p class="pb-0 mb-0">Preferências</p>
              <v-icon class="float-right mt-n6 mr-2"
                >mdi-tune-variant</v-icon
              >
            </div>
            <div
              v-else
              @click="() => (classUtils.preferencesDialog = true)"
              class="slider pt-2 pl-3"
            >
              <p class="pb-0 mb-0">Preferências</p>
              <v-icon class="float-right mt-n6 mr-2"
                >mdi-tune-variant</v-icon
              >
            </div>
          </v-col>
          <v-col v-if="!noPreAloc" cols="4" md="5" class="col-m">
            <!-- PRE_ALOCACAO INPUT -->
            <v-select
              v-if="edit && editPublic"
              dense
              v-model="editPlaceholder.pre_alocacao"
              :items="rooms"
              label="Alocação"
              outlined
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
            </v-select>
            <v-select
              v-else-if="edit && !editPublic"
              dense
              persistent-hint
              hint="Opcional: escolher sala para esta turma."
              v-model="editPlaceholder.pre_alocacao"
              :items="rooms"
              label="Pré-alocação"
              outlined
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  class="text-overline"
                  @click="() => (editPlaceholder.pre_alocacao = '')"
                  >Nenhuma</v-list-item
                >
              </template>
            </v-select>
            <v-select
              v-else-if="!edit && editPublic"
              dense
              v-model="classObj.pre_alocacao"
              :items="rooms"
              label="Alocação"
              outlined
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
            </v-select>
            <v-select
              v-else
              dense
              persistent-hint
              hint="Opcional: escolher sala para esta turma."
              v-model="classObj.pre_alocacao"
              :items="rooms"
              label="Pré-alocação"
              outlined
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  class="text-overline"
                  @click="() => (editPlaceholder.pre_alocacao = '')"
                  >Nenhuma</v-list-item
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="10" class="col-m mt-1">
            <!-- ACESSIVEL CHECKBOX -->
            <v-checkbox
              v-if="edit"
              v-model="editPlaceholder.pcd"
              :label="`Necessita sala acessível.`"
              class="col-m mt-n1 float-left"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="classObj.pcd"
              :label="`Necessita sala acessível.`"
              class="col-m mt-n1 float-left"
            ></v-checkbox>
            <HelpIcon
              class="float-left"
              :text="'Há discente com mobilidade reduzida ou usuário de cadeira de rodas.'"
              small
            />
          </v-col>

          <v-btn
            @click="handleAddClass"
            class="mx-auto mt-5"
            color="primary"
            >Adicionar</v-btn
          >
        </v-row>
      </v-card>

      <v-dialog
        v-model="classUtils.preferencesDialog"
        persistent
        max-width="500"
      >
        <!-- Dialog to edit perferences on edit mode -->
        <SetPreferences
          v-if="edit"
          :preferences="editPlaceholder.preferencias"
          :close="() => (classUtils.preferencesDialog = false)"
        />

        <!-- Dialog to edit perferences -->
        <SetPreferences
          v-else
          :preferences="preferences"
          :close="() => (classUtils.preferencesDialog = false)"
        />
      </v-dialog>
    </section>
  </div>
</template>

<script>
import SetPreferences from "../SetPreferences.vue";
import HelpIcon from "../HelpIcon.vue";

import { departaments } from "@/api/util";

export default {
  name: "PaasForms",
  components: {
    SetPreferences,
    HelpIcon,
  },
  props: {
    option: String,
    edit: Boolean,
    add: Function,
    addDep: Function,
    addRoomType: Function,
    roomTypes: Array,
    departaments: Array,
    close: Function,
    rooms: Array,
    editObject: Object,
    trigger: Boolean,
    noPreferences: Boolean,
    noPreAloc: Boolean,
    editPublic: Boolean,
  },
  watch: {
    //updates editPlaceHolder every time editObject changes
    editObject(newValue) {
      this.editPlaceholder = JSON.parse(JSON.stringify(newValue));
    },
    //trigger has the dialog value, so every time the user opens the form, the values are reset
    trigger() {
      if (this.option == "rooms" && !this.edit) {
        this.roomTypesCopy = [...this.roomTypes];
        this.preferences = this.departaments.map((el) => {
          return { name: el, value: 0 };
        });
        this.room = {
          bloco: "",
          nome: "",
          preferencias: undefined,
          capacidade: 0,
          tipo: "",
          acessivel: false,
          excecao: "", //string
        };
      }
      if (this.option == "classes" && !this.edit) {
        this.preferences = this.roomTypes.map((el) => {
          return { name: el, value: 0 };
        });
        this.classObj = {
          codigo: "",
          turma: "",
          departamento: "",
          horario: "",
          nome: "",
          docente: "",
          preferencias: undefined,
          alunos: 0,
          pre_alocacao: "",
          pcd: false,
        };
      }
    },
  },
  data() {
    return {
      name: "Exemplo novo input",
      departamentsList: [],
      room: {
        bloco: "",
        nome: "",
        preferencias: undefined,
        capacidade: 0,
        tipo: "",
        acessivel: false,
        excecao: "", //string
      },
      classObj: {
        codigo: "",
        turma: "",
        departamento: "",
        horario: "",
        nome: "",
        docente: "",
        preferencias: undefined,
        alunos: 0,
        pre_alocacao: "",
        pcd: false,
      },
      classUtils: {
        preferencesDialog: false,
      },
      holdAdd: false,
      roomTypeDialog: false,
      preferencesDialog: false,
      roomTypesCopy: [],
      preferences: [],
      editPlaceholder: this.$props.edit
        ? JSON.parse(JSON.stringify(this.$props.editObject))
        : "",
      classRules: {
        codigo: [(v) => !!v || "É necessário o código"],
        turma: [(v) => !!v || "Adicione a turma"],
        departamento: [(v) => !!v || "Adicione o departamento"],
        horario: [
          (v) =>
            /([2-7])+[MTN]([1-6])+/.test(v) ||
            "Utilize o formato de horário da UFPB (e.g. 23M12)",
        ],
        nome: [(v) => !!v || "Obrigatório"],
        docente: [(v) => !!v || "Obrigatório"],
        alunos: [(v) => !!v || "Obrigatório"],
      },
      roomRules: {
        bloco: [(v) => !!v || "Adicione um bloco"],
        nome: [(v) => !!v || "Defina um nome"],
        capacidade: [(v) => !!v || "adicione uma capaciade"],
        tipo: [
          (v) => !!v || "Escolha um tipo de sala",
          (v) => v != "ADICIONAR NOVO TIPO" || "Escolha um tipo",
        ],
        excecao: [
          (v) =>
            v == "" ||
            /([2-7])+[MTN]([1-6])+/.test(v) ||
            "Utilize o formato de horário da UFPB (e.g. 23M12)",
        ],
      },
    };
  },
  mounted() {
    this.departamentsList = departaments();
  },
  created() {
    switch (this.option) {
      case "rooms":
        if (!this.edit) {
          this.roomTypesCopy = [...this.roomTypes];
          this.preferences = this.departaments.map((el) => {
            return { name: el, value: 0 };
          });
        }
        break;
      case "classes":
        if (!this.edit) {
          this.preferences = this.roomTypes.map((el) => {
            return { name: el, value: 0 };
          });
        }
        break;
    }
  },
  methods: {
    saveRoomType(e) {
      this.room.type = e;
      this.roomTypesCopy.push(e);
      console.log(e);
    },
    handleNewRoomType(e) {
      this.roomTypesCopy.unshift(e);
      this.room.tipo = e;
    },
    handleSelect() {
      this.roomTypeDialog = true;
    },
    handlePreferencia() {
      this.preferencesDialog = true;
    },
    // validate room input
    verifyRoom(room) {
      if (room.bloco == "") return false;
      if (room.nome == "") return false;
      if (room.capacidade <= 0) return false;
      if (room.tipo == "" || room.tipo == "ADICIONAR NOVO TIPO")
        return false;
      if (
        room.excecao != "" &&
        !/([2-7])+[MTN]([1-6])+/.test(room.excecao)
      )
        return false;
      return true;
    },
    handleAddRoom() {
      if (this.edit) {
        if (this.editPlaceholder.excecao == undefined)
          this.editPlaceholder.excecao = "";
        if (this.verifyRoom(this.editPlaceholder)) {
          this.add(this.editPlaceholder);
          this.holdAdd = false;
          this.close();
        } else {
          this.holdAdd = true;
        }
      } else {
        if (this.verifyRoom(this.room)) {
          this.room.preferencias = JSON.parse(
            JSON.stringify(this.preferences)
          );
          this.add(this.room);

          this.addRoomType([this.room.tipo]);

          this.holdAdd = false;
          this.close();
        } else {
          this.holdAdd = true;
        }
      }
    },
    //validate class input
    verifyClass(tempClass) {
      if (tempClass.codigo == "") return false;
      if (tempClass.turma == "") return false;
      if (tempClass.departamento == "") return false;
      if (!/([2-7])+[MTN]([1-6])+/.test(tempClass.horario))
        return false;
      if (tempClass.nome == "") return false;
      if (tempClass.docente == "") return false;
      if (tempClass.alunos <= 0) return false;
      return true;
    },
    handleAddClass() {
      if (this.edit) {
        if (this.verifyClass(this.editPlaceholder)) {
          this.add(this.editPlaceholder);
          this.holdAdd = false;
          this.close();
        } else {
          this.holdAdd = true;
        }
      } else {
        if (this.verifyClass(this.classObj)) {
          this.classObj.preferencias = JSON.parse(
            JSON.stringify(this.preferences)
          );
          this.add(this.classObj);
          this.addDep([this.classObj.departamento]);
          this.holdAdd = false;
          this.close();
        } else {
          this.holdAdd = true;
        }
      }
    },
    handleClose() {
      this.close();
    },
    handleSaveTypes(types) {
      this.roomTypesCopy = [...types, "ADICIONAR NOVO TIPO"];
    },
    handleSaveDepartaments(departament) {
      this.preferences = departament.map((el) => {
        return { name: el, value: 0 };
      });
      this.$emit("update:departaments", departament);
    },
  },
};
</script>

<style scoped>
.c-b {
  clear: both;
}

.card {
  padding: 50px;
  background-color: white;
}

.btn-search {
  position: absolute;
  top: 76px;
  right: 110px;
}

.btn-add {
  position: absolute;
  top: 76px;
  right: 272px;
}

.last-input {
  float: left;
  width: 65%;
}

.col-m {
  padding: 5px 5px 5px 0;
}

.clear {
  clear: both;
}

.slider {
  height: 40px;
  border: solid 1px grey;
  border-radius: 4px;
  color: grey;
  cursor: pointer;
}

.text-red {
  color: red;
}

.card-no-preference {
  min-height: 150px;
  padding: 20px;
  background-color: #eaeaea;
}
</style>
