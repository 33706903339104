<template>
  <Routes>
    <NavItem icon="mdi-television" route="/paas"> Dashboard </NavItem>

    <NavItem icon="mdi-door" route="/paas-infra">
      Infraestrutura
    </NavItem>

    <NavItem
      v-if="isPaasEditor"
      icon="mdi-file-refresh"
      route="/paas-solicitar"
    >
      Solicitações
    </NavItem>
    <NavItem icon="mdi-play-circle" route="/paas-simulador">
      Simulador
    </NavItem>
    <NavItem icon="mdi-history" route="/paas-historico">
      Histórico
    </NavItem>
    <NavItem
      v-if="isPaasEditor"
      icon="mdi-share-variant"
      route="/paas-publicar"
    >
      Publicar
    </NavItem>

    <NavItem icon="mdi-arrow-left" route="/">
      Voltar para home
    </NavItem>
  </Routes>
</template>

<script>
import Routes from "./Routes";
import NavItem from "./NavItem";

export default {
  name: "PaasRoutes",
  components: {
    NavItem,
    Routes,
  },
  computed: {
    isPaasEditor() {
      return this.$store.getters.userIsPaasEditor;
    },
  },
};
</script>
