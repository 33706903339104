<template>
  <section>
    <div class="main">
      <p class="text-h4">{{ title }}</p>
      <p class="mw">{{ text }}</p>
      <v-progress-linear
        v-if="type == 'loading'"
        class="loading mb-10"
        color="blue"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-img
        v-if="type == 'login'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/login.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'loading'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/loading.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'ok'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/ok.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'team'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/team.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'sorry'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/sorry.svg"
      ></v-img>
      <v-img
        v-else-if="type == '401'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/401.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'setup'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/setup.svg"
      ></v-img>
      <v-img
        v-else-if="type == 'infra'"
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/building.svg"
      ></v-img>
      <v-img
        v-else
        class="mt-n4 ml-3 mb-4"
        width="400"
        src="../assets/not-found.svg"
      ></v-img>
      <v-btn v-if="btn" class="blue" dark @click="btnFunction">{{
        btn
      }}</v-btn>
      <v-btn v-if="btn2" :disabled="loadingBtn2" :loading="loadingBtn2" class="mt-8" color="primary" outlined dark @click="clickBtn2">{{
        btn2
      }}</v-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    title: String,
    text: String,
    btn: String,
    btn2: String,
    loadingBtn2: {
      type: Boolean,
      default: false
    },
    btnFunction: Function,
    type: String,
  },
  methods: {
    clickBtn2() {
      this.$emit('click-btn2');
    }
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 200px;
}

.mw {
  max-width: 600px;
  text-align: center;
}
</style>
