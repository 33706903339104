import axios from "axios";
import store from "@/store/vuex";

const server_uri = process.env.VUE_APP_SERVER_URI;


export function reopenRequest(hash) {
    return new Promise((resolve, reject) => {
  
      const idVinculo = store.getters.getIdVinculo;
      console.log('open', hash);

      const body = {
       hash,
       idVinculo: idVinculo,
      }
  
      axios
      .post(`${server_uri}/db/paas/request/reopen`,
        body,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        reject([]);
      });
    });
  }