<template>
    <div class="main" :style="style">
        <Header title="Simulador" titleColor="white" />
        <section class="container">
            <PaasMainCard :title="display.title" :name="display.name" :save="display.save"
                :subtitle="`${display.subtitle ? input.name : ''}`" :handleAction="handleAction" :close="display.close"
                :dotted="display.dotted" :idx="display.index" :back="display.back" :addSearch="display.addSearch"
                :next="display.next" :assistent="display.assistent">
                <slot>
                    <div>

                        <!-- SHOW START TAB -->
                        <PaasSimuladorDisplay v-if="display.name == 'start'" option="start" :name="input.name"
                            :newName="test" :start="() => handleAction('next')" />



                        <!-- SHOW SET ROOMS TAB -->
                        <div v-if="display.name == 'set-rooms'">

                            <v-dialog v-model="addRoomDialog" persistent max-width="600">
                                <PaasForms option="rooms" :add="(e) => addRoom([e])" :roomTypes="input.roomTypes"
                                    :addRoomType="addRoomType" :departaments="input.departaments" :addDep="addDep"
                                    :trigger="addRoomDialog" :close="() => addRoomDialog = false" />

                            </v-dialog>

                            <!-- rooms data table -->
                            <div :class="`${isMobile ? 'mt-n6' : 'mt-n3'} table`">
                                <div class="search mt-5">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                                        hide-details></v-text-field>
                                </div>
                                <v-data-table :height="heightTableRooms" :items-per-page="-1" hide-default-footer
                                    :headers="headers" :items="exibRooms" :search="search">
                                    <!-- Add help icons next to headers -->
                                    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
                                        {{ header.text }}
                                        <HelpIcon :key="header.value" v-if="header.helpText != undefined" small
                                            :text="header.helpText" />
                                    </template>
                                    <!-- allow search without special characters -->
                                    <template v-slot:item.all="{ item }">
                                        <p class="mb-0">{{ item.bloco }}
                                        </p>
                                    </template>
                                    <template v-slot:item.acessivel="{ item }">
                                        <p class="mb-0">
                                            <v-icon v-if="item.acessivel" color="blue">mdi-wheelchair</v-icon> {{
                                                item.acessivel ? '' : 'Não'
                                            }} Acessível
                                        </p>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon small class="mr-2" @click="handleEdit(item)">
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon small @click="handleDelete(item)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                        <p>Nenhuma sala adicionada</p>
                                    </template>
                                </v-data-table>

                                <v-btn small v-if="exibRooms.length > 0" class="mt-2 blue" dark
                                    @click="handleOpenSaveRoomGroup">
                                    Salvar grupo de Salas</v-btn>

                                <!-- Dialog card to save rooms into the database -->
                                <v-dialog v-model="saveRoomGroup.dialog" persistent max-width="600">
                                    <v-card class="pb-5">
                                        <v-card-title>Salvar Grupo de salas</v-card-title>
                                        <v-card-subtitle>{{ input.rooms.length }} salas serão salvas.</v-card-subtitle>
                                        <v-card-subtitle v-if="saveRoomGroup.verify"><span class="color-red">Digite um
                                                título para este grupo de salas.</span></v-card-subtitle>
                                        <v-text-field class="mx-6 my-4" v-model="saveRoomGroup.name" append-icon="mdi-pen"
                                            label="Título" single-line hide-details></v-text-field>
                                        <div>
                                            <v-checkbox clas v-model="saveRoomGroup.public" class="ml-6"><template
                                                    v-slot:label>
                                                    <p class="mt-4 mr-1">Tornar público</p>
                                                    <HelpIcon class="ml-2" small
                                                        :text="'As salas estarão disponíveis para outros usuários do seu centro.'" />
                                                </template></v-checkbox>

                                        </div>

                                        <v-card-actions>
                                            <v-btn class="primary" @click="handleSaveRoomGroup">Salvar</v-btn>
                                            <v-btn color="grey" plain
                                                @click="() => saveRoomGroup.dialog = false">Cancelar</v-btn>
                                        </v-card-actions>
                                    </v-card>

                                </v-dialog>



                            </div>
                            <v-dialog v-model="edit.dialog" persistent max-width="600">
                                <PaasForms option="rooms" edit :editObject="edit.placeholder" :add="editItem"
                                    :roomTypes="input.roomTypes" :close="() => edit.dialog = false" />
                            </v-dialog>
                        </div>

                        <!-- DELETE CONFIRMATION DIALOG - it's outside of the tab because both set-rooms and set-classes triggers this dialog -->
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-subtitle">Você tem certeza que deseja excluir este
                                    item?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="() => dialogDelete = false">
                                        Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItem">Excluir</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- SHOW SEARCH ROOM TAB -->
                        <PaasSearchOption v-if="display.name == 'search-rooms'" :add="(e) => addRoom(e)"
                            :option="display.name" :addDep="addDep" :handleAction="handleAction"
                            :addRoomTypes="addRoomType" />


                        <!-- SHOW SET CLASS TAB -->
                        <div v-if="display.name == 'set-classes'">

                            <!-- Add new class dialog -->
                            <v-dialog v-model="addRoomDialog" persistent max-width="700">
                                <PaasForms option="classes" :add="addClass" :rooms="roomsList" :addDep="addDep"
                                    :roomTypes="input.roomTypes" :trigger="addRoomDialog"
                                    :close="() => addRoomDialog = false" />
                            </v-dialog>

                            <div class="table">

                                <div class="search mt-3">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                                        hide-details></v-text-field>
                                </div>
                                <v-data-table :height="heightTable" :items-per-page="-1" hide-default-footer
                                    :headers="classHeaders" :items="exibClasses" :search="search">
                                    <!-- Add help icons next to headers -->
                                    <template v-for="header in classHeaders" v-slot:[`header.${header.value}`]>
                                        {{ header.text == 'PCD' ? '' : header.text }}
                                        <div :key="header.value" v-if="header.text == 'PCD'" class="mb-n4">
                                            <v-icon>mdi-wheelchair</v-icon>
                                            <HelpIcon v-if="header.helpText != undefined" small :text="header.helpText" />
                                        </div>

                                    </template>
                                    <!-- allow search without special characters -->
                                    <template v-slot:item.all="{ item }">
                                        <p class="mb-0">{{ item.codigo }}
                                        </p>
                                    </template>
                                    <template v-slot:item.pcd="{ item }">
                                        <p class="mb-0">
                                            <v-icon v-if="item.pcd" color="blue">mdi-wheelchair</v-icon> {{
                                                item.pcd ?
                                                '' : '-'
                                            }}
                                        </p>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon small class="mr-2" @click="handleEdit(item)">
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon small @click="handleDelete(item)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                        <p>Nenhuma sala adicionada</p>
                                    </template>
                                </v-data-table>
                            </div>
                            <v-dialog v-model="edit.dialog" persistent max-width="700">
                                <PaasForms option="classes" edit :editObject="edit.placeholder" :add="editItem"
                                    :roomTypes="input.roomTypes" :rooms="roomsList" :close="() => edit.dialog = false" />
                            </v-dialog>

                        </div>

                        <PaasSearchOption v-if="display.name == 'search-classes'" :option="display.name"
                            :handleAction="handleAction" :add="handleAddClasses" :addDep="addDep" />

                        <!-- SHOW SET PREFERENCES ROOMS TAB -->
                        <div v-if="display.name == 'set-preferences-room'">
                            <TabSetPreferences :preferences="roomsPreferencesFormated" :labels="input.departaments"
                                :noData="!(input.departaments.length > 0 && input.rooms.length > 0)"
                                @update="(idx, value) => input.rooms[idx].preferencias = value" />
                        </div>

                        <!-- SHOW SET PREFERENCES CLASSES TAB -->
                        <div v-if="display.name == 'set-preferences-classes'">
                            <TabSetPreferences :preferences="classesPreferencesFormated" :labels="input.roomTypes"
                                :noData="!(input.roomTypes.length > 0 && input.classes.length > 0)"
                                @update="(idx, value) => input.classes[idx].preferencias = value" />
                        </div>

                        <!-- SHOW ADVANCED OPTIONS TAB -->
                        <AdvancedOptions v-if="display.name == 'advanced-options'" :paasInput="input" capInfo
                            preferencesOptions splitClasses updatePaas @updateCapInfo="updateCapInfo"
                            @updateClassesPreferences="updateClassesPreferences"
                            @updateRoomsPreferences="updateRoomsPreferences" @updateSplitClass="updateSplitClass"
                            @updateClasses="handleUpdateClasses" />

                        <!-- SHOW END TAB -->
                        <PaasSimuladorDisplay v-if="display.name == 'end'" :classes="input.classes.length"
                            :simular="simular" :saveProgress="saveProgress" :rooms="input.rooms.length"
                            :preAloc="preAlocCount" option="end" />

                    </div>
                </slot>
            </PaasMainCard>

        </section>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import PaasMainCard from "@/components/PaasMainCard.vue";
import PaasSearchOption from "@/components/paas/PaasSearchOption.vue";
import PaasSimuladorDisplay from "@/components/paas/PaasSimuladorDisplay.vue";
import PaasForms from "@/components/paas/PaasForms.vue";
import HelpIcon from "@/components/HelpIcon.vue";
import AdvancedOptions from "@/components/paas/AdvancedOptions.vue";
import TabSetPreferences from "@/components/paas/setPreferences/TabSetPreferences.vue";
import latinize from 'latinize';
import { postNewRoomGroup } from "@/api/db";
import {
    solverPaas,
    validateClasses,
    validateRooms,
    validateInput
} from "@/api/paas";

export default {
    name: "PaasSimulador",
    components: {
        Header,
        PaasSearchOption,
        PaasMainCard,
        PaasSimuladorDisplay,
        PaasForms,
        HelpIcon,
        AdvancedOptions,
        TabSetPreferences,
    },
    data() {
        return {
            input: {
                id: '',
                name: 'Nova simulação',
                rooms: [],
                classes: [],
                roomTypes: [],
                departaments: [],
                capInfo: 10,

            },
            search: '',
            addRoomDialog: false,
            edit: {
                dialog: false,
                placeholder: {},
                idx: undefined
            },
            saveRoomGroup: {
                dialog: false,
                copy: [],
                name: '',
                public: true,
                verify: false,
            },
            editDialog: false,
            editPlaceholder: {},
            editPlaceholderId: undefined,
            dialogDelete: false,
            deletePlaceholder: {},
            headers: [
                {
                    text: 'Bloco',
                    align: 'start',
                    value: 'all',
                },
                { text: 'Nome', value: 'nome' },
                { text: 'Capacidade', value: 'capacidade' },
                { text: 'Tipo de Sala', value: 'tipo' },
                { text: 'Preferências', value: 'preferencias', sortable: false, },
                {
                    text: 'Exceção',
                    value: 'excecao',
                    helpText: 'Em alguns casos a sala está indisponível em alguns horários. Se necessário, especifique os horários de indisponibilidade no formato de horário da UFPB (e.g. 23M12) ',
                    sortable: false,
                },
                { text: 'Acessibilidade', value: 'acessivel' },
                { text: 'Editar', value: 'actions', sortable: false },
            ],
            classHeaders: [
                {
                    text: 'Código',
                    align: 'start',
                    value: 'all',
                },
                { text: 'Turma', value: 'turma' },
                { text: 'Nome', value: 'nome' },
                { text: 'Horário', value: 'horario' },
                { text: 'Departamento', value: 'departamento' },
                { text: 'Docente', value: 'docente' },
                { text: 'Preferências', value: 'preferencias' },
                { text: 'Alunos', value: 'alunos' },
                { text: 'Pré-alocação', value: 'pre_alocacao' },
                { text: 'PCD', value: 'pcd', helpText: 'Há discente com mobilidade redusida ou usuário de cadeira de rodas.' },
                { text: 'Editar', value: 'actions', sortable: false },
            ],
            display: {
                title: 'Início',
                name: 'start',
                subtitle: false,
                assistent: '',
                dotted: true,
                back: false,
                next: false,
                close: false,
                index: 0,
                addSearch: false,
                save: false
            },
            searchDisplay: [
                {
                    dotted: false,
                    back: false,
                    next: false,
                    close: true,
                    title: "Buscar Salas",
                    subtitle: false,
                    assistent: '',
                    name: "search-rooms",
                    index: 1,
                    save: false,
                    addSearch: false
                },
                {
                    dotted: false,
                    back: false,
                    next: false,
                    close: true,
                    title: "Buscar turmas a serem alocadas",
                    subtitle: false,
                    assistent: '',
                    name: "search-classes",
                    index: 2,
                    save: false,
                    addSearch: false
                }
            ],
            fluxo: [
                {
                    dotted: true,
                    back: false,
                    next: false,
                    close: false,
                    title: "Início",
                    subtitle: false,
                    assistent: '',
                    name: "start",
                    index: 0,
                    save: false,
                    addSearch: false
                },
                {
                    dotted: true,
                    back: true,
                    next: true,
                    close: false,
                    title: "Infraestrutura",
                    subtitle: true,
                    assistent: 'Acrescente ou edite as informações das salas',
                    name: "set-rooms",
                    index: 1,
                    save: true,
                    addSearch: true
                },
                {
                    dotted: true,
                    back: true,
                    next: true,
                    close: false,
                    title: "Turmas a serem alocadas",
                    subtitle: true,
                    assistent: 'Acrescente novas disciplinas. No botão "Buscar Turmas" você pode buscar turmas pelo nível de ensino, departamento e período.',
                    name: "set-classes",
                    index: 2,
                    save: true,
                    addSearch: true
                },
                {
                    dotted: true,
                    back: true,
                    next: true,
                    close: false,
                    title: "Editar preferências das salas",
                    subtitle: true,
                    assistent: 'Aqui, você pode editar as preferências das salas em relação aos departamentos. Esta etapa é opcional. Se todos os departamentos possuírem o valor 1, significa que esta sala pode alocar disciplinas de qualquer departamento. Não serão alocadas turmas do departamento cujo valor seja 0. Os valores podem variar de 0 a 10.',
                    name: "set-preferences-room",
                    save: true,
                    index: 3,
                    addSearch: false
                },
                {
                    dotted: true,
                    back: true,
                    next: true,
                    close: false,
                    title: "Editar preferências das turmas",
                    subtitle: true,
                    assistent: 'Aqui, você pode editar as preferências das turmas em relação aos tipos de salas. Esta etapa é opcional. Se todos os tipos de sala possuírem o valor 1, significa que esta turma pode ser alocada em qualquer tipo de sala. Não serão alocadas turmas em determidado tipo de sala cujo valor seja 0. Os valores podem variar de 0 a 10.',
                    name: "set-preferences-classes",
                    save: true,
                    index: 4,
                    addSearch: false
                },
                {
                    dotted: true,
                    back: true,
                    next: true,
                    close: false,
                    title: "Opções avançadas",
                    subtitle: true,
                    assistent: 'Aqui você pode dividir turmas para diferentes salas, definir taxa de superlotação e modificar as preferências das turmas. Essas configurações são opcionais. Você pode pular para o próximo passo.',
                    name: "advanced-options",
                    save: true,
                    index: 5,
                    addSearch: false
                },
                {
                    dotted: true,
                    back: true,
                    next: false,
                    close: false,
                    title: "Simular",
                    subtitle: true,
                    assistent: 'Tudo certo!',
                    save: false,
                    name: "end",
                    index: 6,
                    addSearch: false
                },

            ]
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters.userIsAdmin;
        },
        Waiting() {
            return this.$store.getters.getWaitingPAAS;
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 570;
        },
        width() {
            return this.$vuetify.breakpoint.width;
        },
        height() {
            return this.$vuetify.breakpoint.height <= 720 ? 720 : this.$vuetify.breakpoint.height;
        },
        style() {
            return `min-height: ${this.height}px; ${this.width > 1920 ? `width: ${Math.floor(1920 + ((this.width - 1920) / 2))}px;` : 'width: 100%;'}`;
        },
        heightTable() {
            return this.isMobile ? this.height - 450 : this.height - 410;
        },
        heightTableRooms() {
            return this.isMobile ? this.height - 450 : this.height - 450;
        },
        roomsList() {
            return this.input.rooms.map(el => el.bloco + ' ' + el.nome);
        },
        preAlocCount() {
            return this.input.classes.filter(e => e.pre_alocacao != '' && e.pre_alocacao != undefined).length;
        },
        exibRooms() {
            return this.input.rooms.map((e, idx) => {

                //allow search with special characters
                let roomDataLatinaze = latinize(`${e.tipo} ${e.bloco} ${e.nome}`);
                let all = `${e.tipo} ${e.nome} ${e.bloco} ${e.nome} ${roomDataLatinaze}`;

                return {
                    idx: idx,
                    bloco: e.bloco,
                    nome: e.nome,
                    capacidade: e.capacidade,
                    tipo: e.tipo,
                    acessivel: e.acessivel,
                    preferencias: e.preferencias.map(e => e.value).every(e => e == 1) ? 's/ preferências' : (e.preferencias.sort((a, b) => b.value - a.value)).map((el) => el.name).toString(),
                    excecao: e.excecao == '' || e.excecao == undefined || e.excecao == 'undefined' ? 's/ exceção' : e.excecao,
                    all: all,
                }
            })
        },
        exibClasses() {
            return this.input.classes.map((e, idx) => {

                //allow search with special characters
                let classDataLatinaze = latinize(`${e.nome} ${e.docente}`);
                let all = `${e.codigo} ${e.nome} ${e.docente} ${classDataLatinaze}`;

                return {
                    idx: idx,
                    codigo: e.codigo,
                    turma: e.turma,
                    nome: e.nome,
                    departamento: e.departamento,
                    horario: e.horario,
                    alunos: e.alunos,
                    docente: e.docente,
                    pre_alocacao: e.pre_alocacao == '' ? '-' : e.pre_alocacao,
                    pcd: e.pcd,
                    preferencias: e.preferencias.map(e => e.value).every(e => e == 0) ? 'Inviável' : e.preferencias.map(e => e.value).every(e => e == 1)  ? 's/ preferências' : (e.preferencias.sort((a, b) => b.value - a.value)).map((el) => el.name)[0],
                    all: all,
                }
            })
        },
        roomsPreferencesFormated() {
            return this.input.rooms.map((e, idx) => {
                return {
                    name: `${e.bloco} ${e.nome}`,
                    idx: idx,
                    data: e.preferencias
                }
            })
        },
        classesPreferencesFormated() {
            return this.input.classes.map((e, idx) => {
                return {
                    name: `[${e.codigo}-${e.turma}] - ${e.nome.slice(0, 50)}${e.nome.length > 45 ? '...' : ''}`,
                    idx: idx,
                    data: e.preferencias
                }
            })
        }
    },
    created() {
        this.$store.commit("setCurrentComponent", {
            component: "Paas",
        });
    },
    mounted() {
        //get paas input placeholder
        console.log(this.$store.getters.getPaasInputPlaceholder)
        this.input = JSON.parse(JSON.stringify(this.$store.getters.getPaasInputPlaceholder));
        //update placeholder no init values
        this.$store.dispatch("restorePaasInputPlaceholder");
    },
    methods: {
        test(e) {
            this.input.name = e;
        },
        //Main Card Navigation control
        handleAction(e) {
            switch (e) {
                case 'back':
                    if (this.display.index > 0)
                        this.display = this.fluxo[this.display.index - 1];
                    break
                case 'next':
                    if (this.display.index < this.fluxo.length)
                        this.display = this.fluxo[this.display.index + 1];
                    break
                case 'one-dot':
                    this.display = this.fluxo[0];
                    break
                case 'two-dot':
                    this.display = this.fluxo[1];
                    break
                case 'three-dot':
                    this.display = this.fluxo[2];
                    break
                case 'four-dot':
                    this.display = this.fluxo[3];
                    break
                case 'five-dot':
                    this.display = this.fluxo[4];
                    break
                case 'six-dot':
                    this.display = this.fluxo[5];
                    break
                case 'seven-dot':
                    this.display = this.fluxo[6];
                    break
                case 'search-rooms':
                    this.display = this.searchDisplay[0];
                    break
                case 'search-classes':
                    this.display = this.searchDisplay[1];
                    break
                case 'close':
                    this.display = this.fluxo[this.display.index];
                    break
                case 'save':
                    this.saveProgress();
                    break
                case 'open-add-room':
                    this.addRoomDialog = true;
                    break
                case 'open-add-class':
                    this.addRoomDialog = true;
                    break

            }
        },
        //open save room group dialog
        handleOpenSaveRoomGroup() {
            //init with default values
            this.saveRoomGroup = {
                dialog: true,
                name: '',
                public: false,
                copy: this.input.rooms,
                verify: false,
            }
        },

        //save room group into the database
        handleSaveRoomGroup() {
            //name can't be empyt
            if (this.saveRoomGroup.name.length == 0)
                //show warning
                this.saveRoomGroup.verify = true;
            else {
                //send post request
                postNewRoomGroup(this.saveRoomGroup.copy, this.saveRoomGroup.name, this.saveRoomGroup.public);
                //close dialog
                this.saveRoomGroup.dialog = false;
            }
        },
        // open delete dialog and copy selected item to placeholder
        handleEdit(selected) {
            if ('bloco' in selected)
                this.edit.placeholder = JSON.parse(JSON.stringify(this.input.rooms[selected.idx]));
            else
                this.edit.placeholder = JSON.parse(JSON.stringify(this.input.classes[selected.idx]));
            this.edit.idx = selected.idx
            this.edit.dialog = true;
        },
        editItem(item) {
            if ('bloco' in item) {
                this.input.rooms[this.edit.idx] = JSON.parse(JSON.stringify(item));
                this.input.rooms.push({}); this.input.rooms.pop(); //it updates the data table
            } else {
                this.input.classes[this.edit.idx] = JSON.parse(JSON.stringify(item));
                this.input.classes.push({}); this.input.classes.pop(); //it updates the data table
            }
        },
        handleDelete(selected) {
            this.deletePlaceholder = selected;
            this.dialogDelete = true;
        },
        //if user confirm delete
        deleteItem() {
            if ('bloco' in this.deletePlaceholder) {
                this.input.rooms.splice(this.deletePlaceholder.idx, 1);
            } else {
                this.input.classes.splice(this.deletePlaceholder.idx, 1);
            }
            this.dialogDelete = false;
        },
        handleAddClasses(select) {
            // creates a array of preferences with value = 0
            let preferences = this.input.roomTypes.map(e => { return { name: e, value: 1 } });

            //adds the new preference array to the select list
            select = select.map(e => { return { ...e, preferencias: preferences } });

            //adds it to the input 
            this.input.classes = validateClasses(select, this.input.classes);
        },
        addClass(item) {
            this.input.classes = validateClasses([item], this.input.classes);
        },
        addRoom(item) {
            this.input.rooms = validateRooms(item, this.input.rooms);
        },
        addRoomType(roomTypes) {

            //verify if there is a new roomType
            roomTypes.forEach(e => {
                let idx = this.input.roomTypes.indexOf(e);
                if (idx < 0) {
                    //add if it is new
                    this.input.roomTypes.push(e);

                    //update capInfo
                    if (isNaN(this.input.capInfo) && this.input.capInfo != undefined) {
                        this.input.capInfo.push({ name: e, value: 10 });
                    }
                }
            });

            //update all classes to include all roomTypes preferences
            this.input.classes.forEach(e => {

                let prefNames = e.preferencias.map(el => el.name)

                this.input.roomTypes.forEach(roomType => {
                    if (prefNames.indexOf(roomType) == -1) {
                        e.preferencias.push({
                            name: roomType,
                            value: 1,
                        });
                    }
                })
            })
        },
        addDep(deptos) {

            //verify if there is a new departament
            deptos.forEach(e => {
                let idx = this.input.departaments.indexOf(e);
                if (idx < 0) {
                    //add if it is new
                    this.input.departaments.push(e);
                }
            });


            //update all rooms to include all departaments preferences
            this.input.rooms.forEach(e => {

                let prefNames = e.preferencias.map(el => el.name)

                this.input.departaments.forEach(depto => {
                    if (prefNames.indexOf(depto) == -1) {
                        e.preferencias.push({
                            name: depto,
                            value: 1,
                        })
                    }
                })
            })



        },
        //update capInfo - taxa de superlotacao
        updateCapInfo(e) {
            this.input.capInfo = JSON.parse(JSON.stringify(e));
        },
        updateSplitClass(e) {
            this.input.classes.splice(e.old.idx, 1);
            this.input.classes = validateClasses(e.new, this.input.classes);
        },
        handleUpdateClasses(newClasses) {
            this.input.classes = JSON.parse(JSON.stringify(newClasses));
            this.handleAction('third-dot');
        },
        updateClassesPreferences(option, roomType, value, exclusive) {

            switch (option) {
                case 'update':
                    this.input.classes.forEach(e => {

                        let change = true;

                        e.preferencias.every((el) => {
                            if (el.name != roomType && el.value > 1) {
                                change = false;
                                return false;
                            }
                        })

                        if (change) {
                            e.preferencias.forEach(el => {
                                if (el.name == roomType)
                                    el.value = value;
                                else
                                    el.value = exclusive ? 0 : 1;
                            })
                        }
                    })
                    break
                case 'updateSelected': {
                    roomType.forEach(e => {
                        let idx = this.input.classes.indexOf(e);
                        this.input.classes[idx].preferencias = value
                    });
                    break
                }
                case 'reset':
                    //update each class preferences, set default value = 1
                    this.input.classes.forEach(el => {
                        el.preferencias.forEach(e => {
                            e.value = 1;
                        });
                    });
                    break
            }
        },
        updateRoomsPreferences(option, dept, value, exclusive) {

            switch (option) {
                case 'update':
                    this.input.rooms.forEach(e => {

                        let change = true;

                        e.preferencias.every((el) => {
                            if (el.name != dept && el.value > 1) {
                                change = false;
                                return false;
                            }
                        })

                        if (change) {
                            e.preferencias.forEach(el => {
                                if (el.name == dept)
                                    el.value = value;
                                else
                                    el.value = exclusive ? 0 : 1;
                            })
                        }
                    })
                    break
                case 'updateSelected': {
                    dept.forEach(e => {
                        let idx = this.input.rooms.indexOf(e);
                        this.input.rooms[idx].preferencias = value
                    });
                    break
                }
                case 'reset': {

                    console.log('resete')
                    //update each class preferences, set default value = 1
                    this.input.rooms.forEach(el => {
                        el.preferencias.forEach(e => {
                            e.value = 1;
                        });
                    });

                    break
                }

            }
            this.input = JSON.parse(JSON.stringify(this.input));
        },
        saveProgress() {
            if (this.input.classes.length > 0 || this.input.rooms.length > 0) {
                console.log(this.input)
                this.$store.dispatch('updateEditSave', this.input);
                alert("Salvo!\nVocê pode encontrar a simulação salva no histórico. Lembrando que as configuração da simulação não são salvas automaticamente.")
            } else {
                alert("Adicione classes ou turmas!")
            }
        },
        async simular() {

            let { error, warning } = validateInput(this.input);
            if (this.Waiting >= 3)
                error += `Há ${this.Waiting} simulações sendo executadas. Por favor aguarde.\n`
                    ;

            if (error.length > 0) {
                alert(error);
            } else if (warning.length > 0) {
                if (confirm(warning)) {

                    let obj = {
                        name: this.input.name,
                        rooms: this.input.rooms,
                        classes: this.input.classes,
                        roomTypes: this.input.roomTypes,
                        departaments: this.input.departaments,
                        capInfo: this.input.capInfo == undefined ? 10 : this.input.capInfo,
                    };


                    let res = await solverPaas(obj);
                    if (res) {
                        this.$router.push({ path: "/paas-historico" });
                    } else
                        alert('Algo deu errado, tente novamente mais tarde');

                }
            } else {
                let obj = {
                    name: this.input.name,
                    rooms: this.input.rooms,
                    classes: this.input.classes,
                    roomTypes: this.input.roomTypes,
                    departaments: this.input.departaments,
                    capInfo: this.input.capInfo == undefined ? 10 : this.input.capInfo,
                };


                let res = await solverPaas(obj);
                if (res) {
                    console.log(res);
                    this.$router.push({ path: "/paas-historico" });
                } else
                    alert('Algo deu errado, tente novamente mais tarde');

            }

        },
    }


};
</script>

<style scoped>
.btn-search {
    position: absolute;
    top: 76px;
    right: 110px;
}

.btn-add {
    position: absolute;
    top: 76px;
    right: 272px;
}

.container {
    position: absolute;
    max-width: 1600px;
    left: 0
}

.search {
    width: 30%;
    border: solid 1px grey;
    border-radius: 5px;
}

.color-red {
    color: red;
}

.v-text-field {
    padding-top: 0px;
    padding-left: 5px;
    margin-top: 0px;
}

.main {
    background-image: linear-gradient(90deg, #1491ff, #0346b6);
}

.table {
    scroll-behavior: auto;
}

@media only screen and (max-width: 570px) {
    .search {
        margin-top: 70px;
        width: 100%;
    }
}
</style>