import axios from "axios";

const server_uri = process.env.VUE_APP_SERVER_URI;

export function findClasses(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${server_uri}/paas/getSigaaTurmas`,
        {
          params
        },
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        reject("not found");
      });
  });
}
