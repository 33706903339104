<template>
  <section class="clear">
    <v-row v-if="!component">
      <v-col cols="12" md="2">
        <v-select
          dense
          v-model="input.nivelDeEnsino"
          :items="select.nivelDeEnsino"
          label="Nível de Ensino"
          item-text="n"
          item-value="v"
          outlined
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          dense
          v-model="input.unidade"
          :items="departamentos"
          item-text="name"
          item-value="id"
          label="Unidade"
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          type="number"
          label="Ano"
          v-model="input.ano"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          dense
          v-model="input.periodo"
          :items="[1, 2, 3, 4]"
          label="Período"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn v-if="loading" color="blue">
          <v-progress-circular
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          v-else
          @click="handleSearch"
          :loading="loading"
          :disabled="loading"
          color="blue"
          dark
          >buscar</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          label="Código"
          v-model="input.codigo"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3"
        ><v-text-field
          dense
          outlined
          label="Nome da disciplina"
          v-model="input.nome"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="3">
        <v-text-field
          dense
          outlined
          type="number"
          label="Ano"
          v-model="input.ano"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          dense
          v-model="input.periodo"
          :items="[1, 2, 3, 4]"
          label="Período"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn v-if="loading" color="blue">
          <v-progress-circular
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          v-else
          @click="handleSearch"
          :loading="loading"
          :disabled="loading"
          color="blue"
          dark
          >buscar</v-btn
        >
      </v-col>
    </v-row>

    <text-divider
      class="pointer"
      button
      @click.native="component = !component"
      :text="
        component
          ? 'trocar para buscar por departamento'
          : 'trocar para buscar por componente'
      "
      :append-icon="'mdi-magnify'"
    />
  </section>
</template>

<script>
import TextDivider from "@/components/TextDivider.vue";
import { departaments } from "@/api/util";
import { findClasses } from "@/api/sigaa";

export default {
  name: "SearchClassesInput",
  components: {
    TextDivider,
  },
  data() {
    return {
      expand: false,
      loading: false,
      component: false,
      input: {
        nivelDeEnsino: "G",
        unidade: null,
        ano: 2024,
        periodo: 2,
        codigo: "",
        nome: "",
      },
      departamentos: [],
      select: {
        nivelDeEnsino: [
          { n: "Graduação", v: "G" },
          { n: "Mestrado", v: "M" },
          { n: "Doutorado", v: "D" },
        ],
        input: {
          nivelDeEnsino: true,
          unidade: true,
          ano: true,
          periodo: true,
          codigo: false,
          nome: false,
        },
      },
    };
  },
  //regras
  // watch: {
  //   "select.input.nivelDeEnsino"(value) {
  //     if (value)
  //       this.resetSelectInput(true, true, true, true, false, false);
  //   },
  //   //apenas o nome
  //   "select.input.unidade"(value) {
  //     if (value) {
  //       this.changeSome(["codigo", "nome"], false);
  //       this.changeSome(["periodo", "ano"], true);
  //     } else
  //       this.changeSome(["nivelDeEnsino", "periodo", "ano"], false);
  //   },
  //   "select.input.ano"(value) {
  //     if (value) {
  //       this.changeSome(["codigo", "nome"], false);
  //       this.changeSome(["unidade", "periodo"], true);
  //     } else
  //       this.changeSome(
  //         ["nivelDeEnsino", "periodo", "unidade"],
  //         false
  //       );
  //   },
  //   "select.input.periodo"(value) {
  //     if (value) {
  //       this.changeSome(["codigo", "nome"], false);
  //       this.changeSome(["unidade", "ano"], true);
  //     } else
  //       this.changeSome(["nivelDeEnsino", "ano", "unidade"], false);
  //   },
  //   //apenas o codigo
  //   "select.input.codigo"(value) {
  //     if (value)
  //       this.resetSelectInput(
  //         false,
  //         false,
  //         false,
  //         false,
  //         true,
  //         false
  //       );
  //   },
  //   //apenas o nome
  //   "select.input.nome"(value) {
  //     if (value)
  //       this.resetSelectInput(
  //         false,
  //         false,
  //         false,
  //         false,
  //         false,
  //         true
  //       );
  //   },
  // },
  methods: {
    resetSelectInput(v1, v2, v3, v4, v5, v6) {
      this.select.input = {
        nivelDeEnsino: v1,
        unidade: v2,
        ano: v3,
        periodo: v4,
        codigo: v5,
        nome: v6,
      };
    },
    // changeSome(list, value) {
    //   list?.forEach((e) => {
    //     this.select.input[e] = value;
    //   });
    // },
    // atLeastOne() {
    //   return Object.entries(this.select.input)
    //     .map((_) => _[1])
    //     .includes(true);
    // },
    getComponentParams() {
      this.resetSelectInput(
        false,
        false,
        true,
        true,
        this.input.codigo != "",
        this.input.nome != ""
      );

      let params = {
        ano: "ano",
        periodo: "periodo",
        codigo: "codigo",
        nome: "nome",
      };

      let value = Object.entries(this.select.input)
        .filter((e) => e[1])
        .map((e) => `${params[e[0]]}=${this.input[e[0]]}`);

      return `?${value.join("&")}`;
    },
    getParams() {
      this.resetSelectInput(true, true, true, true, false, false);

      let params = {
        nivelDeEnsino: "nivel",
        unidade: "unidade",
        ano: "ano",
        periodo: "periodo",
      };

      let value = Object.entries(this.select.input)
        .filter((e) => e[1])
        .map((e) => `${params[e[0]]}=${this.input[e[0]]}`);

      return `?${value.join("&")}`;
    },
    handleSearch() {
      this.loading = true;

      this.$emit("start-request", false, []);

      let params = this.component
        ? this.getComponentParams()
        : this.getParams();

      findClasses(params)
        .then((res) => {
          console.log(res);
          if (res == "not found")
            this.$emit("finish-request", false, []);
          else this.$emit("finish-request", true, res);
        })
        .catch((err) => {
          this.$emit("finish-request", false, []);
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.departamentos = departaments();
  },
};
</script>

<style scoped>
.clear {
  clear: both;
  width: 100%;
}

.pointer {
  cursor: pointer;
}
</style>
