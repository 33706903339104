<template>
  <div class="d-flex align-center justify-center">
    <!-- Linha antes do texto -->
    <div
      class="flex-grow-1 mx-2"
      style="height: 1px; background-color: #ccc"
    ></div>

    <!-- Texto e ícones -->

    <v-btn v-if="button" color="primary" outlined rounded>
      <v-icon v-if="prependIcon">{{ prependIcon }}</v-icon>
      <span class="mx-2">{{ text }}</span>
      <v-icon v-if="appendIcon">{{ appendIcon }}</v-icon>
    </v-btn>

    <div v-else class="d-flex align-center px-8">
      <v-icon v-if="prependIcon">{{ prependIcon }}</v-icon>
      <span class="mx-2">{{ text }}</span>
      <v-icon v-if="appendIcon">{{ appendIcon }}</v-icon>
    </div>

    <!-- Linha depois do texto -->
    <div
      class="flex-grow-1 mx-2"
      style="height: 1px; background-color: #ccc"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TextDivider",
  props: {
    text: String,
    appendIcon: String,
    prependIcon: String,
    button: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
