<template>
  <div>
    <v-card v-if="!temp.edit && !onlyEdit && !onlyAdd">
      <v-icon
        v-if="editable"
        class="icon-edit float-right mb-4 mt-6 pr-4 "
        @click="() => (this.temp.edit = true)"
      >
        mdi-square-edit-outline
      </v-icon>

      <div class="pt-12" v-if="departament">
        <v-slider
          class="px-8"
          :thumb-size="24"
          thumb-label="always"
          v-for="(departament, idx) in temp.preferences"
          :key="idx"
          :min="0"
          :max="10"
          v-model="departament.value"
          :label="departament.name"
        ></v-slider>
      </div>

      <div class="pt-12" v-else>
        <v-slider
          class="px-8"
          :thumb-size="24"
          thumb-label="always"
          v-for="(departament, idx) in temp.preferences"
          :key="idx"
          :min="0"
          :max="10"
          v-model="departament.value"
          :label="departament.name"
        ></v-slider>
      </div>

      <v-expand-transition>
        <div v-if="error">
          <p class="text-caption color-red px-8">
            <span class="mr-2"
              ><v-icon color="error">mdi-alert-circle</v-icon></span
            >Ao menos um valor precisa ser maior que zero (0).
          </p>
        </div>
      </v-expand-transition>

      <v-card-actions>
        <v-btn
          fab
          dark
          small
          class="btn-save mb-5"
          color="primary"
          @click="handleClose"
        >
          <v-icon class="icon-save ">mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="onlyAdd">
      <v-card-title>Adicionar item</v-card-title>

      <v-container v-if="!add.added">
        <v-row>
          <v-col cols="8">
            <v-text-field
              dense
              class="input"
              outlined
              label="Item"
              v-model="add.name"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn @click="addItem" class="btn-plus" color="primary"
              >+</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <!-- <div v-for="(item, idx) in temp.preferenceNames" :key="idx">
                <p class="ml-4" v-if="item != 'ADICIONAR NOVO TIPO'">{{ item }}</p>
            </div>

            <v-card-actions>
                <v-btn class="mt-4" color="primary" @click="addItem">
                    Salvar
                </v-btn>
                <v-btn class="mt-4" color="secondary" text @click="handleCancel">
                    cancelar
                </v-btn>

            </v-card-actions> -->
    </v-card>

    <!-- edit items -->
    <v-card v-if="onlyEdit || temp.edit">
      <v-card-title>Adicionar ou remover item</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-text-field
              dense
              class="input"
              outlined
              label="Item"
              v-model="temp.preferenceName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              @click="addNewItem"
              class="btn-plus"
              color="primary"
              >+</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <div v-for="(item, idx) in temp.preferenceNames" :key="idx">
        <v-icon
          v-if="item != 'ADICIONAR NOVO TIPO'"
          class="icon float-left px-4"
          :name="idx"
          @click="removeItem"
          >mdi-delete</v-icon
        >
        <p v-if="item != 'ADICIONAR NOVO TIPO'">{{ item }}</p>
      </div>

      <v-card-actions>
        <v-btn class="mt-4" color="primary" @click="saveItems">
          Salvar
        </v-btn>
        <v-btn
          class="mt-4"
          color="secondary"
          text
          @click="handleCancel"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SetPreferences",
  props: {
    departament: Boolean,
    preferences: Array,
    editable: Boolean,
    onlyEdit: Boolean,
    onlyAdd: Boolean,
    close: Function,
    save: Function
  },
  data() {
    return {
      temp: {
        preferences: this.$props.preferences,
        edit: false,
        preferenceName: undefined,
        preferenceNames:
          this.$props.onlyEdit || this.$props.onlyAdd
            ? this.$props.preferences
            : this.$props.preferences.map(e => e.name)
      },
      add: {
        name: "",
        added: false
      },
      error: false
    };
  },
  watch: {
    //update preferences
    preferences(newPreferences) {
      this.temp.preferences = newPreferences;
      this.preferenceNames =
        this.$props.onlyEdit || this.$props.onlyAdd
          ? this.$props.preferences
          : this.$props.preferences.map(e => e.name);
      this.error = false;
      this.add = {
        name: "",
        added: false
      };
    }
  },
  methods: {
    addItem() {
      this.save(this.add.name);
      this.close();
    },
    addNewItem() {
      if (this.onlyAdd) {
        this.temp.preferenceNames.unshift(this.add.name);
        this.add.added = true;
      } else if (
        this.temp.preferenceName != undefined &&
        this.temp.preferenceName != ""
      ) {
        this.temp.preferenceNames.unshift(this.temp.preferenceName);
        this.temp.preferenceName = undefined;
      }
    },
    removeItem(e) {
      this.temp.preferenceNames.splice(Number(e.target.name), 1);
    },
    handleClose() {
      if (this.temp.preferences.every(e => e.value == 0)) {
        this.error = true;
        return;
      }
      this.error = false;
      this.close();
    },
    saveItems() {
      if (this.onlyEdit) {
        this.$emit("@update", this.temp.preferenceNames);
        this.save(this.temp.preferenceNames);
        this.close();
      } else {
        this.save(this.temp.preferenceNames);
        this.temp.preferences = this.temp.preferenceNames.map(e => {
          let res = this.temp.preferences.find(el => el.name == e);
          if (res) return res;
          else return { name: e, value: 0 };
        });
      }
      this.temp.edit = false;
    },
    handleCancel() {
      if (this.onlyEdit) this.close();
      else if (this.onlyAdd) {
        if (this.add.added) this.temp.preferenceNames.shift();
        this.add.added = false;
        this.close();
      } else this.temp.edit = false;
    }
  }
};
</script>

<style scoped>
.icon-edit {
  font-size: 30px;
  clear: both;
}

.icon-edit:hover {
  color: var(--v-primary-base);
}

.btn-save {
  margin: 0 auto;
}

.color-red {
  color: red;
}
</style>
